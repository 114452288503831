
import Vue from '@/vueTyped';
import moment from 'moment';

export default Vue.extend({
    inheritAttrs: false,

    props: {
        type: {
            type: String,
            default: 'date',
        },

        label: {
            type: String,
            required: true,
        },

        value: {
            type: String,
            default: '',
        },

        min: {
            type: String,
            default: '',
        },

        max: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            nowDate: moment().format('YYYY-MM-DD'),
            isOpen: false,
            internalValue: this.value || '',
        };
    },

    computed: {
        formattedValue(): string {
            if (!this.value) {
                return '';
            } else if (this.type === 'time') {
                return moment(this.value, 'HH:mm').format('h:mm A');
            } else {
                return moment(this.value).format('LL');
            }
        },
    },

    watch: {
        value(value) {
            this.nowDate = moment().format('YYYY-MM-DD'); // Keep this up to date for when the instance lives multiple days.
            this.internalValue = value;
        },
    },

    methods: {
        clear() {
            this.$emit('input', '');
            this.isOpen = false;
        },

        confirm() {
            this.$emit('input', this.internalValue ?? '');
            this.isOpen = false;
        },
    },
});
