
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import { trackActionsPageEvent } from '@/tracking';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                welcome: 'Welcome, {name}!',
                prompt: 'Check out some first actions you can take.',
            },
        },
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        showActionsPagePrompt(): boolean {
            const everViewedPage = this.currentUser?.userSettings?.lastViewedActionsPage;
            const ignored = this.currentUser?.userSettings?.ignoredActionsPrompt;
            return (this.currentUser !== null) && !everViewedPage && !ignored;
        },
    },

    methods: {
        resetViewedAndIgnoredState() {
            this.$store.dispatch('updateSettings', {
                lastViewedActionsPage: '',
                ignoredActionsPrompt: '',
            });
        },

        dismissActionsPagePrompt() {
            this.$store.dispatch('updateSettings', {
                ignoredActionsPrompt: new Date().toISOString(),
            });
        },

        trackActionsPageBannerClick() {
            trackActionsPageEvent('Clicked Actions Page Banner');
        },
    },
});
