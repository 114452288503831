
import Vue from '@/vueTyped';

export default Vue.extend({
  name: 'BaseInput',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      // default to single space to force smaller label treatment
      default: ' ',
    },
    optional: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    show: false,
  }),
  methods: {
    handleNativeInputChange(event: InputEvent) {
      const input = event.target;

      if (!(input instanceof HTMLInputElement)) return;
      if (input.type !== 'number') return;
      if (!input.step) return;

      // On change, ensure there's a decimal point displayed if the step allows for one.

      const step = parseFloat(input.step);
      const decimalPlaces = (step.toString().split('.')[1] ?? '').length;
      const needsDecimal = decimalPlaces > 0 && !input.value.includes('.');

      if (needsDecimal) {
        setTimeout(() => {
          input.value = input.valueAsNumber.toFixed(decimalPlaces);
          this.$emit('input', input.value);
          this.$emit('change', input.value);
        });
      }
    },
  },
});
