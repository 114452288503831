
import Vue from '@/vueTyped';
import moment from 'moment';

// Note that while the value of BaseDatetimePicker is always a string (an empty string if there's no value set),
// this component's value is always an array of two values, and unset dates in the array are null.

export default Vue.extend({
    i18n: {
        messages: {
            en: { about: 'About' },
            es: { about: 'Acerca de' },
        }
    },

    props: {
        tag: {
            type: String,
            default: '',
        },

        value: {
            type: Array as () => (string | null)[],
            default: () => [null, null],
        },
    },

    computed: {
        rangeDescription(): string | null {
            return this.value[0] && this.value[1] ? moment(this.value[0]).to(this.value[1], true) : null;
        },
    },

    methods: {
        handleChange(index: number, value: string) {
            let result = [...this.value];
            result[index] = value;
            result = result.map(value => value || null);

            this.$emit('input', result);
        },
    },
});
