
import BaseInput from '@/components/BaseInput.vue';
import BaseSelect from '@/components/BaseSelect.vue';
import TimeRangePicker from '@/components/TimeRangePicker.vue';
import Vue from '@/vueTyped';
import { Investigation, QuestionDataType, StructuredQuestion } from '../../types';
import FeetInchesInput from '../FeetInchesInput.vue';
import MarkdownOutput from '../MarkdownOutput.vue';
import SearchInput from '../SearchInput.vue';
import SelectListInput from '../SelectListInput.vue';

export default Vue.extend({
    components: {
        MarkdownOutput
    },

    props: {
        investigation: {
            type: Object as () => Investigation | null,
            default: null,
        },
        structuredQuestions: {
            type: Array as () => StructuredQuestion[] | null,
            default: null,
        },
        answers: {
            type: Object as any,
            default: () => ({}),
        },
        baseDate: {
            type: Object,
            default: null,
        },
    },
    computed: {
        allQuestions(): StructuredQuestion[] {
            const investigationQuestions = this.investigation ? this.investigation.structuredQuestions : [];
            return this.structuredQuestions ?? investigationQuestions;
        },
    },
    methods: {
        questionComponent(question: StructuredQuestion) {
            if (question.dataType === QuestionDataType.DATETIME) {
                return 'base-date-picker';
            } else if ([QuestionDataType.DECIMAL, QuestionDataType.NUMERIC].includes(question.dataType)) {
                if (question.additionalInfo.translate_unit?.[question.unit] === 'FEET_AND_INCHES') {
                    return FeetInchesInput;
                }
                return BaseInput;
            } else if (question.dataType === QuestionDataType.BOOLEAN) {
                return BaseSelect;
            } else if (question.dataType === QuestionDataType.SELECT) {
                if (question.additionalInfo.options_source) {
                    return SearchInput;
                } else if (question.additionalInfo.list) {
                    return SelectListInput;
                } else {
                    return BaseSelect;
                }
            } else if (question.dataType === QuestionDataType.TIMERANGE) {
                return TimeRangePicker;
            } else {
                return BaseInput;
            }
        },

        questionProps(q: StructuredQuestion) {
            let props: any = {
                label: q.title,
                hint: [q.description, q.warning].join('\n').trim(),
                min: q.additionalInfo.min,
                max: q.additionalInfo.max,
            };

            switch (q.dataType) {
                case QuestionDataType.DECIMAL:
                    props = {
                        ...props,
                        type: 'number',
                        inputmode: 'decimal',
                        step: 0.1,
                    };
                    break;
                case QuestionDataType.BOOLEAN:
                    props = {
                        ...props,
                        items: [
                            { text: 'Yes', value: true },
                            { text: 'No', value: false },
                        ],
                        returnObject: true,
                    };
                    break;
                case QuestionDataType.NUMERIC:
                    props = {
                        ...props,
                        type: 'number',
                        inputmode: 'numeric',
                        step: 1,
                    };
                    break;
                case QuestionDataType.DATETIME:
                    props = {
                        ...props,
                        type: 'time',
                        clearable: true,
                    };
                    break;
                case QuestionDataType.SELECT:
                    if (q.additionalInfo.options_source) {
                        props = {
                            ...props,
                            source: q.additionalInfo.options_source,
                            acceptTextInput: q.additionalInfo.accept_text_input,
                            menuProps: { maxHeight: 160, offsetOverflow: false },
                        };
                    } else if (q.additionalInfo.list) {
                        props = {
                            ...props,
                            options: q.additionalInfo.options,
                            multiple: q.additionalInfo.multiple,
                        };
                    } else {
                        props = {
                            ...props,
                            items: q.additionalInfo.options,
                            returnObject: true,
                            itemText: 'label',
                        };
                    }
                    break;
                default:
                    break;
            }
            return props;
        },
    },
});
