
import { defineComponent } from 'vue';

type Primitive = string | number | boolean;

export default defineComponent({
    props: {
        options: { type: Array as () => { value: Primitive, label: string }[], default: () => [] },
        multiple: { type: Boolean, default: false },
        value: { type: [String, Number, Boolean, Array], default: () => undefined },
    },

    data() {
        return {
            internalId: Math.random().toString(36).split('.')[1],
        };
    },

    computed: {
        HANDLES_OWN_HINT(): true {
            // HACK: This key exists to tell PostDetailsFormPartial to pass the hint as a slot.
            return true;
        },

        inputType(): 'checkbox' | 'radio' {
            return this.multiple ? 'checkbox' : 'radio';
        },
    },

    methods: {
        handleChange(event: InputEvent) {
            if (!(event.currentTarget instanceof HTMLElement)) return;
            const inputs = Array.from(event.currentTarget.querySelectorAll('input'));
            const checkedInputs = inputs.filter(input => input.checked);
            const values = checkedInputs.map(input => input.value);
            const value = this.multiple && values.length > 0 ? values : values[0]; // So, `undefined` if multiple and empty
            this.$emit('input', value);
        },
    },
});
