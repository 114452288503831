
import Vue from '@/vueTyped';

export default Vue.extend({
    inheritAttrs: false,

    props: {
        value: {
            type: [String, Object],
            default: null,
        },

        label: {
            required: true,
            type: String,
        },

        placeholder: {
            type: String,
            default: '--',
        },

        items: {
            required: true,
            type: Array,
        },

        returnObject: {
            type: Boolean,
            default: false,
        },

        itemText: {
            type: String,
            default: 'text',
        },

        itemDescription: {
            type: String,
            default: 'description',
        },

        itemValue: {
            type: String,
            default: 'value',
        },
    },

    data() {
        return {
            selectOpen: false,
        };
    },

    computed: {
        internalValue: {
            get(): any {
                return this.value;
            },

            set(value: any) {
                this.$emit('input', value);
                this.$emit('change', value);
            },
        },

        mobileValueString(): string {
            if (typeof this.items[0] === 'string') {
                return this.value;
            } else if (this.returnObject) {
                return this.value?.[this.itemText];
            } else {
                const valueItem = this.items.find((item: any) => item[this.itemValue] === this.value);
                return (valueItem as any)?.[this.itemText];
            }
        },
    },
});
