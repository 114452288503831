
import { defineComponent } from 'vue';

export default defineComponent({
    i18n: {
        messages: {
            en: {
                feet: 'FEET',
                inches: 'INCHES',
            },

            es: {
                feet: 'PIES',
                inches: 'PULGADAS',
            },
        },
    },

    props: {
        value: { type: Number, default: undefined },
    },

    data() {
        return {
            feet: '',
            inches: '',
        };
    },

    computed: {
        empty(): boolean {
            return this.feet === '' && this.inches === '';
        },
    },

    watch: {
        feet: 'emitValue',
        inches: 'emitValue',
    },

    mounted() {
        if (this.value !== undefined) {
            this.factorValue();
        }
    },

    methods: {
        emitValue() {
            if (this.empty) {
                this.$emit('input', undefined);
            } else {
                const feet = parseFloat(this.feet) || 0;
                const inches = parseFloat(this.inches) || 0;
                this.$emit('input', feet * 12 + inches);
            }
        },

        handleBlur() {
            if (!this.empty) {
                this.factorValue();
            }
        },

        factorValue() {
            const value = this.value || 0;
            this.feet = String(Math.floor(value / 12) || '');
            this.inches = String(value % 12);
        },
    },
});
