
import Vue from '@/vueTyped';
export default Vue.extend({
    props: {
        fixedAction: {
            type: Boolean,
            default: false,
        },
        prependIcon: {
            type: String,
            default: '',
        },
        neverActive: {
            type: Boolean,
            default: false,
        },
        pressed: {
            type: Boolean,
            default: false,
        },
        fauxLink: {
            type: Boolean,
            default: false,
        },
    },
});
