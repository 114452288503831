
import Vue from '@/vueTyped';
// import debounce from 'lodash/debounce';
import { CurrentUser, Investigation, LngLat, MBAddressObj, PostQueryParameters, Region } from '@/types';
import OverlayModal from '@/layouts/OverlayModal.vue';
import LocationSelector from '@/components/location/LocationSelector.vue';
import { reverseGeocode } from '@/util.mapbox';
import { trackSightingsFeedFilter } from '@/tracking';
import orderBy from 'lodash/orderBy';
import BaseFieldset from './BaseFieldset.vue';
import { TranslateResult } from 'vue-i18n';
import MarkdownOutput from './MarkdownOutput.vue';
// import moment from 'moment';

let startWithMenuOpen = true;

type DistanceOption = {
    value: string;
    label: TranslateResult;
    isDefault?: true;
};

export default Vue.extend({
    components: {
        OverlayModal,
        LocationSelector,
        BaseFieldset,
        MarkdownOutput,
    },

    data() {
        return {
            presetsMenuOpen: startWithMenuOpen,
            gettingCurrentLocation: false,
            searchModalOpen: false,
            searchFormValues: {
                region: false as string | false,
                location: null as MBAddressObj | null,
                distance: null as DistanceOption | null,
                investigation: null as Investigation | null,
                questions: [] as string[],
                fromDate: '',
                toDate: '',
                search: '',
            },
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        activePreset(): string {
            const queryParamsCount = Object.entries(this.$route.query).filter(([key, value]) => {
                // TODO: This is sloppy, we should understand which keys we're looking for.
                return Boolean(value) && key !== 'sort';
            }).length;

            // TODO: This doesn't work for Region menus.
            const queriedRegion = this.clientGroupsRegionsAndInvestigations.find(({ region, investigations }) => {
                const investigationMatches = this.$route.query.investigationId && investigations.find(i => i.id === this.$route.query.investigationId);
                return investigationMatches && region.id === this.$route.query.regionId;
            });

            if (queryParamsCount <= 2 && queriedRegion) {
                return ['client-group', this.$route.query.regionId, this.$route.query.investigationId].filter(Boolean).join('-');
            } else if (this.homeLocationQuery && this.$route.query.near === this.homeLocationQuery.near) {
                return 'home';
            } else if (queryParamsCount === 0) {
                return 'world';
            } else if (this.currentLocationQuery && this.$route.query.near === this.currentLocationQuery.near) {
                return 'current';
            } else {
                return 'search';
            }
        },

        clientGroupRegions(): Region[] {
            const allRegions = this.currentUser?.clientGroups?.flatMap(group => group.regions) ?? [];
            return orderBy(Array.from(new Set(allRegions)), 'label');
        },

        clientGroupsRegionsAndInvestigations(): { region: Region, investigations: Investigation[] }[] {
            const results = [];

            for (const group of this.currentUser?.clientGroups ?? []) {
                for (const region of group.regions) {
                    results.push({
                        region,
                        investigations: group.investigations.map(fromGroup => {
                            return this.$store.state.investigations.items.find((fromState: Investigation) => fromState.id === fromGroup.id);
                        }).filter(Boolean) as Investigation[],
                    });
                }
            }

            return results;
        },

        // Note on the `toFixed` calls here: by fixing known location coordinates to fewer decimal places,
        // we can differentiate them from locations from the search dialog.

        homeLocationQuery(): PostQueryParameters | null {
            if (this.currentUser?.userSettings?.homeSightingsQuery) {
                const query = JSON.parse(this.currentUser?.userSettings.homeSightingsQuery);
                const oldParamsFormatStillStored = 'lon' in query;
                if (oldParamsFormatStillStored) {
                    return {
                        near: [query.lon, query.lat, query.distance].join(','),
                    };
                } else {
                    return query;
                }
            } else if (this.currentUser?.lng && this.currentUser?.lat) {
                return {
                    near: [
                        this.currentUser.lng.toFixed(4),
                        this.currentUser.lat.toFixed(4),
                        this.defaultDistanceOption.value,
                    ].join(','),
                };
            } else {
                return null;
            }
        },

        currentLocationQuery(): PostQueryParameters | null {
            if (this.$store.state.activeLocation) {
                return {
                    near: [
                        this.$store.state.activeLocation.center[0].toFixed(4),
                        this.$store.state.activeLocation.center[1].toFixed(4),
                        this.defaultDistanceOption.value,
                    ].join(','),
                };
            } else if (localStorage.mostRecentCurrentLocationQuery) {
                // We'll store the most recent current location locally
                // so we don't have to request it for the button to be active when the page loads.
                return JSON.parse(localStorage.mostRecentCurrentLocationQuery);
            } else {
                return null;
            }
        },

        searchQuery(): any {
            const result: any = {
                regionId: this.searchFormValues.region || undefined,
                investigationId: this.searchFormValues.investigation?.id || undefined,
                structuredQuestion: this.searchFormValues.questions.length !== 0 ? this.searchFormValues.questions : undefined,
                fromDate: this.searchFormValues.fromDate || undefined,
                toDate: this.searchFormValues.toDate || undefined,
                search: this.searchFormValues.search.trim() || undefined,
            };

            if (this.searchFormValues.location) {
                result.near = [
                    this.searchFormValues.location.center[0].toFixed(6),
                    this.searchFormValues.location.center[1].toFixed(6),
                    this.searchFormValues.distance?.value ?? undefined,
                ].join(',');
            }

            for (const [key, value] of Object.entries(result)) {
                if (value === undefined || (Array.isArray(value) && value.length === 0)) {
                    delete result[key];
                }
            }

            return result;
        },

        hasSearchFormInput(): boolean {
            return Object.values(this.searchQuery).some(Boolean);
        },

        distanceOptions(): DistanceOption[] {
            const availableDistances: DistanceOption[] = [];

            if (this.currentUser?.userSettings?.preferredSystemOfMeasure === 'METRIC') {
                if (this.clientGroupRegions.length !== 0) {
                    availableDistances.push(
                        { value: '100m', label: this.$tc('posts.searchDialog.distanceIn.m', 100) },
                        { value: '250m', label: this.$tc('posts.searchDialog.distanceIn.m', 250) },
                        { value: '500m', label: this.$tc('posts.searchDialog.distanceIn.m', 500) },
                        { value: '1km', label: this.$tc('posts.searchDialog.distanceIn.km', 1) },
                    );
                }

                availableDistances.push(
                    { value: '10km', label: this.$tc('posts.searchDialog.distanceIn.km', 10) },
                    { value: '25km', label: this.$tc('posts.searchDialog.distanceIn.km', 25), isDefault: true },
                    { value: '100km', label: this.$tc('posts.searchDialog.distanceIn.km', 100) },
                    { value: '200km', label: this.$tc('posts.searchDialog.distanceIn.km', 200) },
                );
            } else {
                if (this.clientGroupRegions.length !== 0) {
                    availableDistances.push(
                        { value: '500ft', label: this.$tc('posts.searchDialog.distanceIn.ft', 500) },
                        { value: '1000ft', label: this.$tc('posts.searchDialog.distanceIn.ft', 1000) },
                        { value: '1mi', label: this.$tc('posts.searchDialog.distanceIn.mi', 1) },
                    );
                }

                availableDistances.push(
                    { value: '5mi', label: this.$tc('posts.searchDialog.distanceIn.mi', 5) },
                    { value: '20mi', label: this.$tc('posts.searchDialog.distanceIn.mi', 20), isDefault: true },
                    { value: '50mi', label: this.$tc('posts.searchDialog.distanceIn.mi', 50) },
                    { value: '200mi', label: this.$tc('posts.searchDialog.distanceIn.mi', 200) },
                );
            }

            return availableDistances;
        },

        defaultDistanceOption(): DistanceOption {
            const middleFallback = this.distanceOptions[Math.floor((this.distanceOptions.length - 1) / 2)];
            return this.distanceOptions.find(option => option.isDefault) ?? middleFallback;
        },

        investigationOptions(): Investigation[] {
            const none = { id: '', name: 'All Investigations' } as Investigation;
            return [none].concat(this.$store.state.investigations.items);
        },
    },

    watch: {
        ['$route.query']: {
            deep: true,
            immediate: true,
            handler() {
                this.syncSearchFormValues();
            },
        },

        activePreset(activePreset: string) {
            if (this.currentUser) {
                const sightingsQuery = activePreset === 'search' ? this.currentUser.userSettings?.homeSightingsQuery : JSON.stringify(this.$route.query);
                this.$store.dispatch('updateSettings', { sightingsQuery });
            }
        },

        'searchFormValues.investigation'() {
            this.searchFormValues.questions = [];
        },
    },

    async mounted() {
        await this.$store.dispatch('fetchInvestigations');

        this.syncSearchFormValues();

        setTimeout(() => {
            this.presetsMenuOpen = false;
            startWithMenuOpen = false;
        }, 333);
    },

    methods: {
        handlePresetButtonClick(button: string, query: PostQueryParameters = {}) {
            if (button === 'current') {
                if (!this.$store.state.activeLocation) {
                    this.getCurrentLocation();
                    return;
                } else {
                    query = this.currentLocationQuery!;
                }
            }

            this.presetsMenuOpen = false;
            trackSightingsFeedFilter(button, query);
        },

        async getCurrentLocation() {
            this.gettingCurrentLocation = true;

            let { data: { error } } = await this.$store.dispatch('getActiveLocation');

            if (this.currentLocationQuery) {
                try {
                    // Cache this locally so the preset button works on reload.
                    localStorage.mostRecentCurrentLocationQuery = JSON.stringify(this.currentLocationQuery);
                } catch (ignoredError) {
                    // No worries, the query will just appear as a search by location.
                }

                this.$router.push({ query: this.currentLocationQuery as any });
                trackSightingsFeedFilter('current', this.currentLocationQuery);
            } else {
                error = error ?? 'Failed to find your current location.'

                this.$store.dispatch('alertUser', {
                    type: 'error',
                    message: this.currentUser ? error : 'Sign in to see your local feed!',
                });
            }

            this.gettingCurrentLocation = false;
            this.presetsMenuOpen = false;
        },

        async syncSearchFormValues() {
            this.searchFormValues.region = this.$route.query.regionId as string ?? false;

            this.searchFormValues.investigation = this.investigationOptions.find(i => i.id === this.$route.query.investigationId) ?? this.investigationOptions[0] as Investigation;

            this.searchFormValues.questions = Array.isArray(this.$route.query.structuredQuestion) ? (this.$route.query.structuredQuestion as string[]) : this.$route.query.structuredQuestion ? [this.$route.query.structuredQuestion] : [];

            if (this.activePreset !== 'search') {
                return;
            }

            this.searchFormValues.fromDate = this.$route.query.fromDate as string ?? '';
            this.searchFormValues.toDate = this.$route.query.toDate as string ?? '';
            this.searchFormValues.search = this.$route.query.search as string ?? '';

            if (this.$route.query.near) {
                const [lng, lat, distance] = this.$route.query.near.split(',');

                this.searchFormValues.distance = this.distanceOptions.find(d => String(d.value) === distance) ?? this.defaultDistanceOption;

                const coords: LngLat = [parseFloat(lng), parseFloat(lat)];
                const lngChanged = coords[0] !== this.searchFormValues.location?.center[0];
                const latChanged = coords[1]  !== this.searchFormValues.location?.center[1];

                if (lngChanged || latChanged) {
                    const reverseGeocodeCoords = await reverseGeocode(coords, { limit: 1 });
                    this.searchFormValues.location = reverseGeocodeCoords.features[0];
                }
            } else if (this.$route.query.lon && this.$route.query.lat) {
                const coords: LngLat = [
                    parseFloat(this.$route.query.lon as string),
                    parseFloat(this.$route.query.lat as string),
                ];

                this.searchFormValues.distance = this.distanceOptions.find(d => String(d.value) === this.$route.query.distance) ?? this.defaultDistanceOption;

                const lngChanged = coords[0] !== this.searchFormValues.location?.center[0];
                const latChanged = coords[1]  !== this.searchFormValues.location?.center[1];

                if (lngChanged || latChanged) {
                    const reverseGeocodeCoords = await reverseGeocode(coords, { limit: 1 });
                    this.searchFormValues.location = reverseGeocodeCoords.features[0];
                }
            } else {
                this.searchFormValues.location = null;
                this.searchFormValues.distance = this.defaultDistanceOption;
            }
        },

        handleSearchFormSubmission() {
            this.searchModalOpen = false;
            this.$router.push({ query: this.searchQuery });
            trackSightingsFeedFilter('search', this.searchQuery);
            this.presetsMenuOpen = false;
        },

        clearSearchForm() {
            Object.assign(this.searchFormValues, {
                region: false,
                location: null,
                distance: this.defaultDistanceOption,
                investigation: this.investigationOptions[0],
                questions: [],
                fromDate: '',
                toDate: '',
                search: '',
            });
        },
    },
});
