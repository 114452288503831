
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { TRACKER_ORIGIN } from '@/config';
import { defineComponent } from 'vue';
import { getOauthCode } from './OAuth';

export default defineComponent({
    components: {
        LoadingIndicator,
    },

    metaInfo: {
        title: 'Create a Post',
    },

    props: {
        startingPath: { type: String, required: true },
    },

    data() {
        return {
            oauthCode: '',
            error: null as Error | null,
        };
    },

    computed: {
        trackerUrl() {
            const url = new URL(this.startingPath, TRACKER_ORIGIN);
            url.searchParams.set('embedded', 'main-site');

            if (this.oauthCode) {
                url.searchParams.set('code', this.oauthCode);
            }

            return url.href;
        },
    },

    watch: {
        '$store.state.account.currentUser': {
            immediate: true,
            async handler() {
                this.error = null;
                this.oauthCode = '';

                if (this.$store.state.account.currentUser) {
                    try {
                        const params = new URLSearchParams([['client_id', 'isc_embedded_tracker']]);
                        this.oauthCode = await getOauthCode(params);
                    } catch (error) {
                        this.error = error;
                    }
                }
            },
        },
    }
});
