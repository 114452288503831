
import OverlayModal from '@/layouts/OverlayModal.vue';
import { defineComponent } from 'vue';
import { VVirtualScroll } from 'vuetify/lib';
import ActionFooter from './ActionFooter.vue';

type Option = { value: boolean | number | null | string, label?: string };

const SOURCE_ROOT = '/data-sources';

function normalize(value: unknown) {
    // Ignore accents when comparing strings, see https://stackoverflow.com/a/37511463
    return String(value ?? '').toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
}

export default defineComponent({
    i18n: {
        messages: {
            en: {
                placeholder: 'Select...',
                select: 'Select',
                close: 'Close',
            },

            es: {
                placeholder: 'Seleccione...',
                select: 'Seleccione',
                close: 'Cerrar',
            },
        },
    },

    components: {
        ActionFooter,
        OverlayModal,
        VVirtualScroll,
    },

    props: {
        source: { type: String, required: true },
        acceptTextInput: { type: Boolean, default: false },
    },

    data() {
        return {
            window: window,
            buttonHeightInPx: 20,
            listSpaceInPx: 40,
            loading: 0,
            options: [] as Option[],
            showingModal: false,
            inputValue: this.$attrs.value,
        };
    },

    computed: {
        optionsPlusInput(): Option[] {
            const andInputValue = this.acceptTextInput && this.inputValue?.trim() ? [{
                value: this.inputValue,
                label: this.inputValue,
            }] : [];

            return [
                ...this.options,
                ...andInputValue,
            ];
        },

filteredOptions(): Option[] {
            if (this.inputValue === this.$attrs.value) {
                return this.options;
            }

            const inputValueTokens = normalize(this.inputValue?.trim()).split(/\s+/);
            return this.options.filter(option => {
                const optionText = normalize(option.label ?? option.value);
                return inputValueTokens.every(token => optionText.includes(token));
            });
        },
    },

    watch: {
        source: {
            immediate: true,
            handler() {
                this.loadOptionsFromSource();
            },
        },

        '$attrs.value'() {
            this.inputValue = this.$attrs.value;
        },
    },

    methods: {
        async loadOptionsFromSource() {
            try {
                this.loading += 1;
                const sourcePath = this.source.includes('://') ? this.source : `${SOURCE_ROOT}/${this.source}`;

                const response = await fetch(sourcePath);

                if (response.ok) {
                    const options = await response.json() as (string | typeof this.options[number])[];

                    const fullyFormedOptions = options.flatMap(option => {
                        if (typeof option === 'string') {
                            return [{ value: option, label: option }];
                        } else if (option && Object.prototype.hasOwnProperty.call(option, 'value')) {
                            return [option];
                        } else {
                            console.error(`Unexpected option in ${sourcePath}, ${JSON.stringify(option)}`);
                            return [];
                        }
                    });

                    this.options = fullyFormedOptions;
                } else {
                    console.error(`Failed to load options from ${sourcePath}`);
                }
            } finally {
                this.loading -= 1;
            }
        },

        async calculateButtonHeight() {
            if (this.$refs.optionsList instanceof HTMLDivElement) {
                await this.$nextTick();
                this.listSpaceInPx = this.$refs.optionsList.offsetHeight;
                const optionButtons = this.$refs.optionsList.querySelectorAll<HTMLButtonElement>('[data-is-option]');
                this.buttonHeightInPx = Array.from(optionButtons).reduce((total, b) => total + b.offsetHeight, 0) / optionButtons.length;
            }
        },

        handleOptionSelection(item: Option) {
            this.$emit('input', item.value);
            this.$emit('change', item.value);
            this.showingModal = false;
        },
    },
});
