import { render, staticRenderFns } from "./BaseSelect.vue?vue&type=template&id=212268f3&scoped=true"
import script from "./BaseSelect.vue?vue&type=script&lang=ts"
export * from "./BaseSelect.vue?vue&type=script&lang=ts"
import style0 from "./BaseSelect.vue?vue&type=style&index=0&id=212268f3&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "212268f3",
  null
  
)

export default component.exports