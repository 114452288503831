
import PostsFilters from '@/components/PostsFilters.vue';
import PostsList from '@/components/PostsList.vue';
import Vue from '@/vueTyped';
import ActionsPagePrompt from './ActionsPagePrompt.vue';
import GuestFeed from './GuestFeed.vue';

export default Vue.extend({
    metaInfo(): any {
        return {
            title: 'Sightings',
            meta: [{
                name: 'robots',
                content: 'noindex',
            }],
        };
    },

    components: {
        ActionsPagePrompt,
        GuestFeed,
        PostsList,
        PostsFilters,
    },

    data() {
        return {};
    },

    computed: {
        locationQuery(): Record<string, unknown> {
            if (this.$route.query.lon && this.$route.query.lat) {
                // We previously used separate lon/lat/distance params,
                // but Facebook complains when we send users' home coordinates.
                return {
                    lngLat: [parseFloat(this.$route.query.lon ?? 0), parseFloat(this.$route.query.lat ?? 0)],
                    distance: this.$route.query.distance || '1mi',
                };
            } else if (this.$route.query.near) {
                const [lng, lat, distance] = this.$route.query.near.split(',');
                return {
                    lngLat: [parseFloat(lng ?? 0), parseFloat(lat ?? 0)],
                    distance: distance || '1mi',
                };
            } else {
                return {};
            }
        },

        currentUser() {
            return this.$store.state.account.currentUser;
        },

        postsLoaded(): boolean {
            return this.$store.state.posts.asyncStatus === 'success';
        },
    },

    methods: {},
});
