
/* eslint-disable vue/no-mutating-props */

import { CurrentUser, DraftPost, Investigation, StructuredQuestion } from '@/types';
import Vue from '@/vueTyped';
import ActionFooter from '@/components/ActionFooter.vue';
import ImageInput from '@/components/ImageInput.vue';
import ImagePreviewList from '@/components/ImagePreviewList.vue';
import LoadingIndicator from './LoadingIndicator.vue';
import PostDetailsFormPartial from '@/components/web/PostDetailsFormPartial.vue';
import TwoStepAddressSelector from '@/components/location/TwoStepAddressSelector.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import { NLP_API_HOST } from '@/config';
import orderBy from 'lodash/orderBy';
import { timePostCreation, trackPostFormStepEnter, trackSuggestedTagRemoval, trackPostFormStepExit } from '@/tracking';
import BaseDatePicker from './BaseDatePicker.vue';
import { PhotoMetadata } from '@/pages/PostsCreate/index.vue';
import moment from 'moment';

type NlpApiResult = { slug: string };

export default Vue.extend({
    name: 'SteppedPostCreationForm',

    components: {
        ActionFooter,
        ImageInput,
        ImagePreviewList,
        LoadingIndicator,
        PostDetailsFormPartial,
        TwoStepAddressSelector,
        UserAvatar,
        BaseDatePicker,
    },

    props: {
        investigations: {
            type: Array as () => Investigation[],
            default: () => [],
        },

        post: {
            type: Object as () => DraftPost,
            default: () => ({} as DraftPost),
        },

        photoMetadata: {
            type: Object as () => PhotoMetadata,
            required: true,
        },

        errors: {
            type: Object,
            default: () => ({} as { [key: string]: string }),
        },

        saving: {
            type: Boolean,
            default: false,
        },

        saveProgress: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            console,
            blocker: null as string | null,
            fetchingSuggestedTags: 0,
            textCheckedByNlp: false,
            nlpResults: [] as NlpApiResult[],
            saveStarted: 0,
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        steps(): string[] {
            const steps = ['content', 'context', 'tags'];

            if (Object.values(this.deduplicatedQuestions).flat().length !== 0) {
                steps.push('questions');
            }

            return steps;
        },

        currentStep(): string {
            const hash = this.$route.hash.slice(1);
            return this.steps.includes(hash) ? hash : this.steps[0];
        },

        currentStepIndex(): number {
            return this.steps.indexOf(this.currentStep);
        },

        timestampMatchesPhoto(): boolean {
            return Boolean(
                this.post.date &&
                this.post.time &&
                this.post.date === this.photoMetadata.date &&
                this.post.time === this.photoMetadata.time
            );
        },

        daysSinceTimestamp(): number | null {
            if (this.post.date && this.post.time) {
                return moment().diff(`${this.post.date}T${this.post.time}`, 'days');
            } else {
                return null;
            }
        },

        locationMatchesPhoto(): boolean {
            return Boolean(
                this.post.coordinates &&
                this.photoMetadata.coordinates &&
                this.post.coordinates[0] === this.photoMetadata.coordinates[0] &&
                this.post.coordinates[1] === this.photoMetadata.coordinates[1]
            );
        },

        investigationsByCategory(): [string, Investigation[]][] {
            const results: { [id: string]: Investigation[] } = {};

            for (const investigation of this.investigations) {
                const category = investigation.investigationCategory || '$NONE';

                if (results[category] === undefined) {
                    results[category] = [];
                }

                results[category].push(investigation);
            }

            return orderBy(Object.entries(results), '0');
        },

        selectedInvestigations(): Investigation[] {
            return this.investigations.filter(investigation => this.post.investigationIds.includes(investigation.id));
        },

        deduplicatedQuestions(): { [investigationId: string]: StructuredQuestion[] } {
            const askedQuestions = new Set();
            const questionsByInvestigationId: { [investigationId: string]: StructuredQuestion[] } = {};

            for (const investigation of this.selectedInvestigations) {
                for (const question of investigation.structuredQuestions) {
                    if (question.published) {
                        if (!askedQuestions.has(question.title)) {
                            if (questionsByInvestigationId[investigation.id] === undefined) {
                                questionsByInvestigationId[investigation.id] = [];
                            }

                            questionsByInvestigationId[investigation.id].push(question);
                            askedQuestions.add(question.title);
                        }
                    }
                }
            }

            Object.entries(questionsByInvestigationId).forEach(([investigationId, questions]) => {
                questionsByInvestigationId[investigationId] = orderBy(questions, 'order');
            });

            return questionsByInvestigationId;
        },

        totalFileSize() {
            return this.post.photos.reduce((total, photo) => {
                return total + (photo.size ?? 0);
            }, 0);
        },

        secondsRemaining(): number | null {
            this.saveProgress; // Reference this up front to mark it as a dependency.

            const elapsed = Date.now() - this.saveStarted;

            if (this.saveStarted === 0 || elapsed < 1000) {
                return null;
            }

            const percentPerMs = this.saveProgress / elapsed;
            return (1 - this.saveProgress) / (percentPerMs * 1000);
        }
    },

    watch: {
        currentStep: {
            immediate: true,
            handler(currentStep, previousStep) {
                if (previousStep) {
                    trackPostFormStepExit(previousStep);

                    // Another branch I have open right now changes "basics" to "content",
                    // and if I don't put both here I know I'll break it without realizing.
                    // TODO: Remove "basics" check when that change is merged in.
                    if (previousStep === 'basics' || previousStep === 'content') {
                        if (!this.textCheckedByNlp) {
                            this.fetchSuggestedTags(this.post.textBody);
                        }
                    }
                }

                trackPostFormStepEnter(currentStep);
            },
        },

        'post.textBody'() {
            this.textCheckedByNlp = false;
        },

        'post.investigationIds'(currentIds: string[]) {
            const currentSlugs = currentIds.map(id => this.investigations.find(i => i.id === id)?.slug);

            const removed = this.nlpResults.filter(result => !currentSlugs.includes(result.slug));

            if (removed.length !== 0) {
                for (const result of removed) {
                    trackSuggestedTagRemoval(result.slug);
                }
            }
        },

        saveProgress(saveProgress, saveProgressWas) {
            if (saveProgressWas === 0) {
                this.saveStarted = Date.now();
            }
        },
    },

    mounted() {
        timePostCreation();
    },

    methods: {
        async fetchSuggestedTags(text: string) {
            try {
                this.fetchingSuggestedTags += 1;

                const nlpRequestBody = new FormData();
                nlpRequestBody.set('text', text);

                const nlpResponse = await fetch(`${NLP_API_HOST}/predict-topics`, {
                    method: 'POST',
                    body: nlpRequestBody,
                });

                const nlpResults: NlpApiResult[] = await nlpResponse.json();
                this.nlpResults = nlpResults.filter(result => this.investigations.find(investigation => investigation.slug === result.slug));

                for (const result of this.nlpResults) {
                    const investigation = this.investigations.find(investigation => investigation.slug === result.slug);
                    if (investigation && !this.post.investigationIds.includes(investigation.id)) {
                        this.post.investigationIds.push(investigation.id);
                    }
                }

                this.textCheckedByNlp = true;
            } catch (ignoredError) {
                // This is not critical to functionality.
            } finally {
                this.fetchingSuggestedTags -= 1;
            }
        },

        toggleInvestigation(investigation: Investigation) {
            const index = this.post.investigationIds.indexOf(investigation.id);
            if (index === -1) {
                this.post.investigationIds.push(investigation.id);
            } else {
                this.post.investigationIds.splice(index, 1);
            }
        },

        goToNextStep() {
            if (this.currentStep === 'content') {
                if (!this.post.textBody) {
                    this.blocker = 'noText';
                }
            } else if (this.currentStep === 'context') {
                if (!this.post.date || !this.post.time) {
                    this.blocker = 'noDateOrTime';
                } else if (!this.post.location) {
                    this.blocker = 'noLocation';
                } else if (!this.post.coordinates) {
                    this.blocker = 'noCoordinates';
                }
            }

            if (this.blocker) {
                return;
            }

            const nextStep = this.steps[this.currentStepIndex + 1];
            if (nextStep) {
                this.$router.push(`#${nextStep}`);
                this.resetFocus();
            } else {
                this.$emit('submit');
            }
        },

        async resetFocus() {
            await this.$nextTick();
            const heading: HTMLHeadingElement | null = (this.$refs.form as HTMLFormElement).querySelector('[tabindex="-1"]');
            heading?.focus();
            heading?.blur();
        },

        async focusOn(refName: string) {
            await this.$nextTick();
            const target = this.$refs[refName] as any;
            if (target.focus instanceof Function) {
                target.focus();
            } else if (target.$el instanceof HTMLElement) {
                target.$el.querySelector('input, textarea')?.focus();
            }
        }
    },
});
