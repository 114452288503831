
import Vue from '@/vueTyped';
import { LngLat, MBAddressObj } from '@/types';
import debounce from 'lodash/debounce';
import { importMapboxGl, reverseGeocode } from '@/util.mapbox';
import LocationAutocomplete from '@/components/location/LocationAutocomplete.vue';

const CENTER_OF_THE_USA = [-98.5, 39.76];

export default Vue.extend({
    name: 'LocationSelector',
    components: {
        LocationAutocomplete,
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        value: {
            type: Object as () => MBAddressObj,
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: 'Location',
        },
        hint: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            internalValue: null as null | MBAddressObj,
            dialog: false,
            map: {
                base: null as any,
                marker: null as any,
                lastDoubleClick: NaN, // We won't move the pin on double click.
            },
        };
    },
    computed: {
        activeLocation(): null | MBAddressObj {
            return this.$store.state.activeLocation;
        },
        displayLocation(): null | string {
            return this.value?.place_name;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (this.internalValue !== value) {
                    this.internalValue = value;
                }
            },
        },
        internalValue(internalValue) {
            if (internalValue) {
                this.map.marker?.setLngLat(internalValue.center);
                this.map.marker?.addTo(this.map.base);

                const inView = this.map.base?.getBounds().contains(internalValue.center);

                this.map.base?.flyTo({
                    center: internalValue.center,
                    speed: inView ? 2 : 100,
                });
            } else {
                this.map.marker?.remove();
            }
            setTimeout(() => {
                // Resize the map manually after the iOS keyboard slides down.
                // Not sure why this doesn't happen automatically, since something responds to it sliding up.
                this.map.base?.resize();
            }, 1000);
        },
        dialog(dialog, previousDialog) {
            if (dialog && !previousDialog) {
                setTimeout(this.setupMap, 250);
            } else if (!dialog && previousDialog) {
                setTimeout(this.teardownMap, 250);
            }
        },
    },
    methods: {
        teardownMap() {
            // ...
        },
        async setupMap() {
            if (this.map.base) {
                return;
            }

            const Mapbox = await importMapboxGl();

            const defaultCenter = this.value?.center ?? this.activeLocation?.center ?? CENTER_OF_THE_USA;

            this.map.base = new Mapbox.Map({
                container: this.$refs.mapselector as HTMLDivElement,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: this.value?.center as LngLat ?? this.activeLocation?.center ?? CENTER_OF_THE_USA,
                zoom: defaultCenter === CENTER_OF_THE_USA ? 3 : 13,
                trackResize: true,
            });

            this.map.marker = new Mapbox.Marker({ draggable: true });

            if (this.internalValue) {
                this.map.marker.setLngLat(this.internalValue.center);
                this.map.marker.addTo(this.map.base);
            }

            this.map.base.on('load', () => {
                if (this.activeLocation) {
                    this.map.base.addSource('userpoint', {
                        type: "geojson",
                        data: {
                            type: "FeatureCollection",
                            features: [{
                                type: "Feature",
                                geometry: {
                                    type: "Point",
                                    coordinates: this.activeLocation.center,
                                }
                            }],
                        }
                    });

                    this.map.base.addLayer({
                        id: "userpoint",
                        type: "circle",
                        source: "userpoint",
                        paint: {
                            "circle-radius": 5,
                            "circle-color": "#3887be"
                        }
                    });
                }

                // Track double-clicks so we don't move the pin when we're just trying to zoom in.
                this.map.base.on('dblclick', () => {
                    this.map.lastDoubleClick = Date.now();
                });

                this.map.base.on('click', (event: any) => {
                    const { lng, lat } = event.lngLat;
                    this.lookUpCoordinates([lng, lat]);
                });

                this.map.marker.on('dragend', () => {
                    const coords = this.map.marker.getLngLat();
                    this.lookUpCoordinates([coords.lng, coords.lat]);
                });
            });
        },
        lookUpCoordinates: debounce(async function(this: any, coords: [number, number]) {
            const location = await reverseGeocode(coords, { limit: 1 });

            const DOUBLE_CLICK_THRESHOLD = 1000; // The debounce, plus whatever extra time the event needs after its last click.
            const justDoubleClicked = Date.now() - this.map.lastDoubleClick < DOUBLE_CLICK_THRESHOLD;

            if (!justDoubleClicked) {
                this.internalValue = location.features?.[0];
            }
        }, 500),
        confirmMapLocation() {
            this.$emit('change', this.internalValue);
            this.dialog = false;
        },
        discardMapLocation() {
            this.internalValue = this.value;
            this.dialog = false;
        }
    },
});
